import * as Bootstrap from 'react-bootstrap';

export default function SearchFilterModal({show,handleClose}){

	return (

		<Bootstrap.Modal show={show} onHide={handleClose}>
			<Bootstrap.Modal.Header closeButton closeVariant="white">
				Filter
			</Bootstrap.Modal.Header>
			<Bootstrap.Modal.Body>

				<Bootstrap.Form>
					<Bootstrap.Form.Group className="mb-3" >
						<Bootstrap.Form.Label>Lohn min.</Bootstrap.Form.Label>
						<Bootstrap.Form.Control
							type="email"
							placeholder="name@example.com"
							autoFocus
						/>
					</Bootstrap.Form.Group>
					<Bootstrap.Form.Group
						className="mb-3"
						controlId
					>
						<Bootstrap.Form.Label>Distanz max.</Bootstrap.Form.Label>
						<Bootstrap.Form.Control
							type="email"
							placeholder="name@example.com"
							autoFocus
						/>
					</Bootstrap.Form.Group>
				</Bootstrap.Form>

			</Bootstrap.Modal.Body>
			<Bootstrap.Modal.Footer>
				<div role="button" className="p-2 dark-jq" onClick={handleClose}>OK</div>
			</Bootstrap.Modal.Footer>
		</Bootstrap.Modal>
	)
}

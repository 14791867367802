import {DateTime} from "luxon";
import { useState,useEffect } from "react";
import _ from "lodash";
import SearchFilterModal from "./SearchFilterModal";
import axios from 'axios'
import _config from "./config.json";
import SearchBar from "./SearchBar";
import ReactMarkdown from 'react-markdown'
import AISearchJobs from "./AISearchJobs";
import AISearchImages from './AISearchImages.js';
const config = _config[process.env.NODE_ENV || "development"];


export default function AISearch() {
	const [selectedTab,setSelectedTab] = useState("Jobs");

	return (
		(selectedTab === "Jobs") ? 
			<AISearchJobs {...{selectedTab,setSelectedTab}}/>
		:
		(selectedTab === "Bilder") ? 
			<AISearchImages {...{selectedTab,setSelectedTab}}/> 
		:
		<div>
			<p>todo</p>
			<button onClick={()=>setSelectedTab("Jobs")}>back</button>
		</div>

	)
}

import {useState} from "react";
import SearchBar from "./SearchBar";
import axios from "axios";
import _config from "./config.json";
const config = _config[process.env.NODE_ENV || "development"];

export default function NewImage({selectedTab,setSelectedTab}){


	const [image,setImage]=useState(null);

	const postImage=async()=>{

		//submit image as multipart form data

		const formData = new FormData();
		formData.append('file', image);
		console.log(image)

		axios.post(config.apiUrl+'/images', formData)
			.then(response => {
				alert(response.data)
			})
			.catch(error => {
				alert("error")
			});

		// Clear the selected file
		//setImage(null);

	}


	return (
		<div className="light-jq p-3 d-flex flex-column">
			{/* Tabs */}
			<div className="d-flex flex-row gap-3 p-2">
				{["Jobs","Bilder"].map((n)=>
				<div key={n} role="button" className={"h5 "+(n===selectedTab?"":"text-muted")} onClick={()=>setSelectedTab(n)}>
					{n}
				</div>
				)
				}
			</div>
			<h3> Datenerfassung </h3>
			<div className="mt-4 d-block d-inline-flex  flex-column">
				<span> Bild </span>
				<input type="file" name="avatar"  onChange={(e)=>setImage(e.target.files[0])}/>
				<div className="d-flex flex-row mt-1">
					<div className="me-auto"/>
					<div role="button" className="p-2 dark-jq" onClick={postImage}>Post</div> 
				</div>
			</div>


		</div>
	);
}


import _ from 'lodash'
import {Link} from 'react-router-dom'

const index={
	"Daten":{
		"AI Suche":"data/search",
		"Tabelle": "data/table",
		"Datenerfassung":"data/new",
		"Import/Export": "data/import",
		"AI Eigenschaften": "data/aiprops"
	},
	/*
	"Bewerber":{
		"AI Suche":"applicants/search",
		"Tabelle": "applicants/table",
		"Neuer Bewerber":"applicants/new",
		"Import/Export": "applicants/import",
	},
	"Bilder":{
		"AI Suche":"images/search",
		"Gallerie":"images/gallery",
		"Neues Bild":"images/new"
	},
	*/
	"System":{
		"Benutzer":"system/profiles",
		"Systemeinstellungen":"system/settings",
		"Infomationen":"system/info"
	},
	"Statistiken":{
		"AI Eigenschaften":"stats/data",
		"Geographie":"stats/geoStats",
		"Zeit":"stats/time",
		"Kennzahlen":"stats/metrics",
	}



}

export default function NavbarLinkTree({closeNavbar}){

	return (
		<>
			{Object.entries(
				index
			).map(
				([k,v])=>(
					<div className="mb-3" key={k}>
						<div className="mb-1">
							<b>
								{k}
							</b>
						</div>
						{
							Object.entries(v).map(([k,v])=>(
								<div className="ms-3 mb-1 text-nowrap" key={v}>
									<Link  onClick={closeNavbar}  to={v}>{k}</Link>
								</div>
							))
						}
					</div>
				)
			)}
		</>
	)
}

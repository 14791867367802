import React, { useState, useEffect } from 'react';
import { Form, ListGroup,Dropdown,DropdownButton } from 'react-bootstrap';
import axios from 'axios';
import debounce from 'lodash.debounce';

import _config from "./config.json";
const config = _config[process.env.NODE_ENV || "development"];


const distances={
	"0km":	0,
	"5km":	5000,
	"10km":	10000,
	"20km":	20000,
	"50km":	50000,
	"100km":100000,
	"Keine":10000000,
}




const SearchBar = ({setSelectedLocation,selectedLocation,setSelectedDistance,selectedDistance,hideDistance=false}) => {

	const [searchInput, setSearchInput] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [showDropDown,setShowDropDown]=useState(true)


	const setSelectedLocationP=(s)=>{
		setSearchInput(s.placename)
		setSelectedLocation(s)
	}


	useEffect(() => {
		if (searchInput) {
			debouncedFetchSearchResults(searchInput);
		} else {
			setSearchResults([]);
		}
	}, [searchInput]);

	const fetchSearchResults = async (query) => {
		try {
			const response = await axios.get(config.apiUrl+"/geo",{params:{partialLocation:query}})

			setSearchResults(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	const debouncedFetchSearchResults = debounce(fetchSearchResults, 100);

	const renderSearchSuggestions = () => {
		return searchResults.map((result, index) => (
			<Dropdown.Item key={index} value={result} onClick={()=>setSelectedLocationP(result)}>{result.placename}</Dropdown.Item>
		));
	};

	return (
		<div className="d-flex flex-row">
			<div className="flex-grow-1">
				<Form.Control
					className={selectedLocation?"text-selected-jq":""}
					type="text"
					placeholder="Search"
					value={searchInput}
					onChange={(e) => {setSearchInput(e.target.value);setShowDropDown(true);setSelectedLocation(null)}}
				/>
				<Dropdown  show={showDropDown&&(searchResults?.length)} onToggle={setShowDropDown}>
					<Dropdown.Menu rootCloseEvent="click">
						{renderSearchSuggestions()}
					</Dropdown.Menu>
				</Dropdown>
			</div>
			{hideDistance||
			<div  className="flex-grow-1">
				<DropdownButton id="dropdown-basic-button" >
					{Object.keys(distances).map((key)=><Dropdown.Item active={distances[key]===selectedDistance?"active":""} className={"text-muted"} key={key} value={key} onClick={()=>setSelectedDistance(distances[key])}>{key}</Dropdown.Item>)}
				</DropdownButton>
			</div>
			}
		</div>
	);
};

export default SearchBar;


import 'chart.js/auto';
import {Chart, Bar} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js'
import {useState} from 'react'

ChartJS.defaults.color="white"

let months=[
	"Januar",
	"Februar",
	"März",
	"April",
	"Mai",
	"Juni",
	"Juli",
	"August",
	"September",
	"Oktober",
	"November",
	"Dezember"
]
let branches=[
	"Informationstechnologie",
	"Gesundheitswesen",
	"Finanzwesen",
	"Verwaltung",
	"Andere",
]


var seed = 1;
function random() {
	var x = Math.sin(seed++) * 10000;
	return x - Math.floor(x);
}

export const data = {
	labels:months,
	datasets: branches.map(b=>({
		label:b,
		data:months.map(()=>Math.floor(1000*random()))
	}))
};

//white text
let config={
	scales: {
		y: {
			beginAtZero: true,
		},
	},
	plugins: {
		legend: {
			labels: {
				fontColor: 'red' 
			}
		}
	}

}

let options={
	responsive:true,
	scales: {
		y: {
			beginAtZero: true,
			stacked:true,
		},
		x: {
			stacked:true,
		}
	},
}


export default function TimeChart() {


	return(
		<div className={"p-3 light-jq d-flex flex-column "}>
			<h3>Jobs hinzugefügt</h3>




			<Bar data={data} config={config} options={options}/>

		</div>
	)
}


import {useState} from 'react';
import TableView from './TableView';

export default function DataTable(){
	return (
		<div className="light-jq p-3 overflow-auto"> <h3> Datenübersicht </h3>
			<div className="mt-4 d-block d-inline-flex  flex-column ">
				<TableView/>
			</div>
		</div>
	)
}

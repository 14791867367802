import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import NotFound from "./NotFound";
import AISearch from "./AISearch";

import ProfileSettings from "./ProfileSettings";
import AIProps from "./AIProps";
import DataStats from "./DataStats";
import GlobalSettings from "./GlobalSettings";
import DataTable from "./DataTable";
import NewData from "./NewData";
import DataImport from "./DataImport";
import GeoStats from "./GeoStats";
import TimeChart from "./TimeChart";
import Metrics from "./Metrics";

import ProfilesSettings from "./ProfilesSettings";

import SystemInformation from "./SystemInformation";

/*
const index={
	"Jobs":{
		"AI Suche":"jobs/search",
		"Tabelle": "jobs/table",
		"Neuer Job":"jobs/new",
		"Import/Export": "jobs/import",
	},
	"Bewerber":{
		"AI Suche":"applicants/search",
		"Tabelle": "applicants/table",
		"Neuer Bewerber":"applicants/new",
		"Import/Export": "applicants/import",
	},
	"Bilder":{
		"AI Suche":"images/search",
		"Gallerie":"images/gallery",
		"Neues Bild":"images/new"
	},
	"Profil":{
		"Einstellungen":"profile/settings",
		"Ausloggen":"profile/logout",
	},
	"System":{
		"Benutzer":"system/profiles",
		"Globale Einstellungen":"system/settings",
		"Infomationen":"system/about"
	},
	"Statistiken":{
		"Daten":"stats/data",
		"Vermittlungen":"stats/placements",
		"Verwendung":"stats/usage"
	}
}
*/

export default function MainRouter(){

	return(
		<Routes>

			<Route path='/' element={ <Navigate to="/data/search" replace/> }/>

			<Route path="/data/search" element={<AISearch site="jobs"/>} />
			<Route path="/data/table" element={<DataTable site="jobs"/>} />
			<Route path="/data/new" element={<NewData site="jobs"/>} />
			<Route path="/data/import" element={<DataImport site="jobs"/>} />
			<Route path="/data/aiprops" element={<AIProps/>} />

			<Route path="/profile/settings" element={<ProfileSettings/>} />

			<Route path="/system/settings" element={<GlobalSettings/>} />
			<Route path="/system/profiles" element={<ProfilesSettings/>} />
			<Route path="/system/info" element={<SystemInformation/>} />

			<Route path="/stats/data" element={<DataStats/>} />
			<Route path="/stats/geoStats" element={<GeoStats/>} />
			<Route path="/stats/time" element={<TimeChart/>} />
			<Route path="/stats/Metrics" element={<Metrics/>} />

			<Route path="*" element={<NotFound/>} />
		</Routes>
	)
}

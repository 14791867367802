import Table from 'react-bootstrap/Table';
import {useState, useEffect} from 'react';
import axios from 'axios';
import _config from "./config.json";
const config = _config[process.env.NODE_ENV || "development"];

export default function AIProps() {

	const [aiProps, setAiProps] = useState([]);

	useEffect(() => {
		const result = axios.get(config.apiUrl+"/aiprops"
		).then((response) => {
			setAiProps(response.data);
		}).catch((error) => {
			console.log(error);
			setAiProps("error "+error.message);
		});
	}, []);

	const onSubmit= (e) => {
		e.preventDefault();

		//get from data
		const data= new FormData(e.target);
		const title=data.get("title");
		const query=data.get("query");
		const limitedRun=data.get("limitedRun")==="on";

		//send to server
		axios.post(config.apiUrl+"/aiprops",{title,query,limitedRun}
		).then((response) => {
			alert("OK");
		}).catch((error) => {
			alert("Fehler "+error.message);
		});
	}
	const onDelete= (e,aiProp) => {
		e.preventDefault();
		axios.delete(config.apiUrl+"/aiprops/",{data:{id:aiProp.id}}).then((response) => {
			alert("OK");
		})
		.catch((error) => {
			alert("Fehler "+error.message);
		})
	}



	return (
		<div className="light-jq p-3">
			<h3 className="mb-3"> AI Eigenschaften</h3>
			<Table striped bordered hover variant="dark">
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
						<th>Prompt</th>
						<th>Fortschritt</th>
						<th>Limitiert</th>
						<th>Author</th>
					</tr>
				</thead>
				<tbody>
				{
					aiProps===null?
						<tr>
							<td colSpan="4">Lade...</td>
						</tr>
					:
					typeof aiProps==="string"?
						<tr>
							<td colSpan="4">Fehler beim Laden {aiProps}</td>

						</tr>
					:

					aiProps.map((aiProp) => {
						return (
							<tr key={aiProp.id}>
								<td><i 
									className="bi bi-trash text-danger"
									role="button"
									onClick={(e)=>onDelete(e,aiProp)}
								></i></td>
								<td>{aiProp.title}</td>
								<td>{aiProp.query} </td>
								<td>{aiProp.numTotal===0?"Initializing":(((aiProp.numProcessed/aiProp.numTotal)*100).toFixed(2)+"%")}</td>
								<td>{aiProp.limitedRun?"Ja":"Nein"}</td>
								<td>Test User</td>
							</tr>
						)
					}
					)
				}
				</tbody>
			</Table>

			<h3 className="mt-5 mb-3"> Eigenschaft erfassen</h3>
			<form onSubmit={onSubmit}>
				<div className="my-2 form-group">
					<label htmlFor="name">Titel</label>
					<input type="text" className="my-2 form-control" name="title" id="name" placeholder="Name"/>
				</div>
				<div className="my-2 form-group">
					<label htmlFor="prompt">Query</label>
					<input type="text" className="my-2 form-control" name="query" id="prompt" placeholder="Prompt"/>
				</div>
				<div className="my-2 form-group">
					<label htmlFor="prompt">Limitiert</label>
					<input type="checkbox" className="mt-2 ms-2" defaultChecked name="limitedRun" id="limitedRun"/>
				</div>
				<button type="submit" className="mt-3 btn btn-primary">Eigenschaft erfassen</button>
			</form>
		</div>
	)
}
{/*

						<tr>
							<td>Telefonnummer</td>
							<td>"Finde die Telefonnummer der Hauptverwantwortlichen"</td>
							<td>100%</td>
							<td>t.keller</td>
						</tr>
						<tr>
							<td>Bachelor benötigt</td>
							<td>"Sag ja wenn die folgende Stelle einen Bachelor benötigt, sonst nein"</td>
							<td>45% (ca. 3 Stunden)</td>
							<td>b.mueller</td>
						</tr>
						<tr>
							<td>Sprache</td>
							<td>"Finde die Sprache der Stelle"</td>
							<td>3% (ca. 7 Stunden)</td>
							<td>h.müller</td>
						</tr>
						*/}


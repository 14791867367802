
export default function Metrics() {
	const date = new Date();  // 2009-11-10
	const month = date.toLocaleString('de-DE', { month: 'long' });
	return(
		<div className="light-jq p-3">
			<h3> Informationen</h3>
			<div className="mt-4 d-block d-inline-flex  flex-column">
				<h5> Anzahl Jobs: {Intl.NumberFormat('fr-FR').format(50223)}</h5>
				<h5> Anzahl AI Suchen {month} : 124</h5>
				<h5> Anzahl Logins {month} : 24</h5>

			</div>
		</div>
	)
}


import {useEffect, useState} from "react";


export default function DataImport(){
	const [fakeProgress, setFakeProgress] = useState(null);

	const startFakeProgress=()=>{
		setFakeProgress(0);

		let inte=setInterval(()=>{
			setFakeProgress((p)=>Math.min(100,p+1));
		},100);

	}

	const fakeDownload=()=>{
		//download a fake file
		let csv=`
		title,text
		"Titel 1","Text 1"
		"Titel 2","Text 2"
		"Titel 3","Text 3"
		"Titel 4","Text 4"
		`
		var element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
		element.setAttribute('download', "test.csv");

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}



	let timeRemaining=fakeProgress===null?null:100-fakeProgress;


	return (
		<div className="light-jq p-3">
			<h3> Import/Export </h3>
			<div className="mt-4 d-block d-inline-flex  flex-column">
				<div>
					<div role={"button"} className={"p-2 dark-jq "} onClick={()=>startFakeProgress()}>CSV Datei importieren</div>
					{
						fakeProgress===null?
							null:
							<div>
								<div className="m-2 progress">
									<div className="progress-bar" role="progressbar" style={{width: fakeProgress+"%"}} aria-valuenow={fakeProgress} aria-valuemin="0" aria-valuemax="100"></div>
								</div>

								{
									timeRemaining!==0?
										"Importing... "+timeRemaining+" seconds remaining"
										:
										"done"
								}
							</div>
					}
				</div>
				<div role={"button"} className={"my-3 p-2 dark-jq "} onClick={()=>fakeDownload()}>CSV Datei exportieren</div>
			</div>
		</div>
	);
}


import {DateTime} from "luxon";
import { useState,useEffect } from "react";
import _ from "lodash";
import SearchFilterModal from "./SearchFilterModal";
import axios from 'axios'
import _config from "./config.json";
import SearchBar from "./SearchBar";
import ReactMarkdown from 'react-markdown'
const config = _config[process.env.NODE_ENV || "development"];


const keys={
	'company':'Unternehmen',
	'placename':'Ort',
//	'Country':'Land',
	'type':'Pensum'
}

function JobListing({job,index}){

	const colorThreshs=(v)=>{
		if(v>0.70){
			return "#00ff00"
		}else if(v>0.6){
			return "#ffff00"
		}else {
			return "#ff0000"
		}
	}


	const [expanded,setExpanded]=useState(false)
	return(
		<div className="my-2 p-2 dark-jq d-flex flex-column">
			<small>No {index+1}</small>
			<div className="d-flex flex-row">
				<h5>{job.title}</h5>
				<div className="flex-grow-1"></div>
				<span role="button" onClick={()=>setExpanded(e=>!e)}>Details</span>
			</div>
			<div className="row gap-3 mt-4" style={{maxWidth:"600pt"}}>
				<div className="col-1">
					<div><b>Match</b></div>
					<div  style={{color:colorThreshs(job._additional.certainty)}}>{Math.floor(job._additional.certainty*10000)/100}%</div>
				</div>
				{
					Object.entries(keys).map(([key,transl]) => (
						<div className="col" key={transl}>
							<div><b>{transl}</b></div>
							<div>{job[key]}</div>
						</div>
					))
				}
			</div>
			<div className="col gap-5 mt-4" style={{maxWidth:"600pt"}}>

				{
					Object.entries(job.aiProps).map(([key,prop])=>(
						<div className="row gap-1" key={key}>
							<div><b>{key}</b></div>
							<div className="mb-3">{prop}</div>
						</div>
					)

					)
				}

			</div>
			{
				expanded?
					<div className="mt-4 px-4">
						<ReactMarkdown>{job.posting}</ReactMarkdown>
						{/*<div dangerouslySetInnerHTML={{__html:job.posting}}></div>*/}
					</div>
					:
					<div className="mt-3">
						{/*
						<small>
						{job.summary}
						</small>
						*/ }
					</div>

			}
		</div>
	)

}

export default function AISearch({selectedTab,setSelectedTab}) {

	const [results, setResults] = useState(null);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [queryText, setQueryText] = useState("");
	const [withTitle, setWithTitle] = useState(true);
	const [withPosting, setWithPosting] = useState(false);

	const [selectedLocation,setSelectedLocation]=useState(null)

	const [hideSearchBox,setHideSearchBox]=useState(false)

	const [selectedDistance,setSelectedDistance]=useState(10000000)

	const reload=()=>{
		if(!(withTitle||withPosting)){
			return
		}
		setResults("loading")
		axios.get(config.apiUrl+"/jobs",{params:{
			queryText,
			numResults:100,
			withTitle,
			withPosting,
			coords:JSON.stringify(selectedLocation),
			distance:selectedDistance
		}}).then((res)=>{


			setResults(res.data)
		}
		).catch((err)=>{
			console.log(err)
			setResults(null)
		}
		)
	}






	return (
		<>
			<div className={"light-jq d-flex flex-column "}>
				{/* Tabs */}
				<div className="d-flex flex-row gap-3 p-2">
					{["Jobs","Bilder"].map((n)=>
					<div key={n} role="button" className={"h5 "+(n===selectedTab?"":"text-muted")} onClick={()=>setSelectedTab(n)}>
						{n}
					</div>
					)
					}
					<div className="ms-auto"/>
					<i className={"bi me-2 "+(hideSearchBox?"bi-chevron-down":"bi-chevron-up")} role="button" onClick={()=>setHideSearchBox(h=>!h)}></i>
				</div>

				{/* Search params */}
				<div className={"p-3 pt-0 "+(hideSearchBox?"d-none":"")}>
					<textarea className="form-control" rows="10" placeholder="Suchbegriffe eingeben..." onChange={(e)=>setQueryText(e.target.value)}

						onKeyDown={(e)=>{
							if (e.key === 'Enter' && !e.shiftKey) {
								e.preventDefault();
								reload()
							}
						}}
					></textarea>
					<div className="d-flex gap-3 mt-2  align-items-center">
						<div>
							<input type="checkbox" id="titlecb" name="scales" defaultChecked onChange={(e)=>setWithTitle( e.currentTarget.checked)}/>
							<label htmlFor="titlecb" className="ms-1">
								Suche nach Titel
							</label>
						</div>
						<div>
							<input type="checkbox" id="desccb" name="scales" onChange={(e)=>setWithPosting( e.currentTarget.checked)}/>
							<label htmlFor="desccb" className="ms-1">
								Suche nach Beschreibung
							</label>
						</div>
						<div>
							<SearchBar {...{setSelectedLocation,selectedLocation,selectedDistance,setSelectedDistance}}/>
						</div>

						<div className="flex-grow-1"></div>
						<div role="button" className="p-2 dark-jq" onClick={()=>setShowFilterModal(true)}>Filter</div> {withPosting}
						<div role={((withPosting||withTitle))?"button":""} className={"p-2 dark-jq "+(((withPosting||withTitle))?"":"text-muted")} onClick={reload}>Suche</div>
					</div>
				</div>
			</div>
			{/* Search results*/}
			<div className="mt-2 light-jq p-2 flex-grow-1 d-flex flex-column">
				{
					results===null?
						null
						:
						results==="loading"?

							<div className="d-flex flex-grow-1 justify-content-center align-items-center">
								<div className="spinner-border" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							</div>

							:
						results.map((result, index) => (
							<JobListing job={result} key={result._additional.id} index={index}/>
						)
						)
				}
			</div>

			<SearchFilterModal show={showFilterModal} handleClose={()=>setShowFilterModal(false)} />
		</>
	);

}


import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import Navbar from './Navbar';
import ContentPane from './ContentPane.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import SidePanel from './SidePanel.js';
import './App.css';

function App() {
  return (
    <div className="h-100 App d-flex flex-column">
		<Navbar />
		<div className="d-flex flex-grow-1" style={{alignSelf:"stretch",minHeight:"0px"}}>
			<SidePanel />
			<ContentPane />
		</div>
    </div>
  );
}

export default App;



export default function SystemInformation(){
	return (
		<div className="light-jq p-3">
			<h3> Informationen</h3>
			<div className="mt-4 d-block d-inline-flex  flex-column">
				<h5> Version: 5.13.1</h5>
				<h5> Build: 2021-05-01</h5>
				<h5> Lizenz-ID: 123456789</h5>
				<h5> Lizenznehmer: Firma AG</h5>
				<h5> Frontend: 5.13.1</h5>
				<h5> Backend: 5.13.1</h5>
				<h5> Copyright: JobQuery</h5>
			</div>
		</div>
	);
}


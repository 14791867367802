import {useState} from "react";
import SearchBar from "./SearchBar";
import axios from "axios";
import _config from "./config.json";

import NewJob from "./NewJob";
import NewImage from "./NewImage";

const config = _config[process.env.NODE_ENV || "development"];

export default function NewData(){
	const [selectedTab,setSelectedTab]=useState("Jobs");

	
	return (
		(selectedTab === "Jobs") ? 
			<NewJob {...{selectedTab,setSelectedTab}}/>
		:
		(selectedTab === "Bilder") ? 
			<NewImage {...{selectedTab,setSelectedTab}}/> 
		:
		<div>
			<p>todo</p>
			<button onClick={()=>setSelectedTab("Jobs")}>back</button>
		</div>
	)
}


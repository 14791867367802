import MainRouter from './MainRouter.js';

export default function ContentPane() {
	return(
		<div className=" overflow-auto flex-grow-1 d-flex flex-column">
			<div className="container py-3 d-flex flex-column flex-grow-1">
				<MainRouter />
			</div>
		</div>
	)
}

import 'chart.js/auto';
import {Chart, Doughnut} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js'
import {useState} from 'react'

ChartJS.defaults.color="white"


let sampleData= {
	Outdoor:{
		labels: [
			'Ja',
			'Nein',
			'Unsicher'
		],
		datasets: [{
			data: [1000, 3000, 500]
		}],
		query:"finded dieser Job hauptsächlich im freien Statt?"
	},
	Abschluss:{
		labels: [
			'Keine',
			'EFZ',
			'Hochschule',
			'Doktorat',
			'Unsicher'
		],
		datasets: [{
			data: [1000, 3000, 500, 100, 200]
		}],
		query:"Was sind die Voraussetzungen für diesen Job?"
	},
	Computerkentnisse:{
		labels: [
			'Ja',
			'Nein',
			'Unsicher'
		],
		datasets: [{
			data: [3000, 500, 1000]
		}],
		query:"Erfordert dieser Job grundlegende Computerkentnisse?"
	}
};

//white text
let config={
	scales: {
		y: {
			beginAtZero: true
		}
	},
	plugins: {
		legend: {
			labels: {
				fontColor: 'red' 
			}
		}
	}

}



export default function DataStats() {
	const [selectedTab,setSelectedTab] = useState("Outdoor")

	return(
		<div className={"p-3 light-jq d-flex flex-column "}>
			<h3>AI Eigenschaften</h3>

			<div className="d-flex flex-row gap-3 p-2">
				{Object.keys(sampleData).map((n)=>
				<div key={n} role="button" className={"h5 "+(n===selectedTab?"":"text-muted")} onClick={()=>setSelectedTab(n)}>
					{n}
				</div>
				)
				}
			</div>



			<div className="justify-content-center d-flex w-100">
				<div style={{maxHeight:"400pt"}} >
					<Doughnut key={selectedTab} data={sampleData[selectedTab]} config={config}/>
				</div>
			</div>
			<div className="d-flex flex-row justify-content-center">
				<div className="p-2">
					<div className="h5">Input</div>
					<div className="p-2">{sampleData[selectedTab].query}</div>
				</div>
			</div>

		</div>
	)
}


export default function GlobalSettings() {
	return (
		<div className="light-jq p-3">
			<h3> Systemeinstellungen </h3>
			<div className="mt-4 d-block d-inline-flex  flex-column">
				<h5 className="mb-3"> Firma </h5>
				<form>
					<div className="mb-3 form-group">
						<label htmlFor="company">Firmenname</label>
						<input type="text" className="mt-1 form-control" id="company" placeholder="Firmenname" />
					</div>
					<div className="mb-3 form-group">
						<label htmlFor="address">Logo</label>
						<input type="file" className="mt-2 form-control" id="company" placeholder="Firmenname" />
					</div>
					<button type="submit" className="btn btn-primary">Speichern</button>
				</form>


			</div>

		</div>
	);
}

import Table from 'react-bootstrap/Table';

export default function ProfilesSettings(){
	return (
		<div className="light-jq p-3 d-flex flex-column">
			<h3> Benutzerverwaltung</h3>
			<div className="mt-4 d-block d-inline-flex  flex-column">
				<h5> Benutzer</h5>
				<Table striped bordered hover variant="dark">
					<thead>
						<tr>
							<th>Benutzername</th>
							<th>Name</th>
							<th>Rolle</th>
							<th>Letzter Login</th>
							<th>Anzahl Queries</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>t.keller</td>
							<td>Thomas Keller</td>
							<td>Admin</td>
							<td>2021-05-01 12:00:00</td>
							<td>100</td>
						</tr>
						<tr>
							<td>b.storch</td>
							<td>Bernd Storch</td>
							<td>Nutzer</td>
							<td>2021-05-01 12:00:00</td>
							<td>100</td>
						</tr>
						<tr>
							<td>h.müller</td>
							<td>Hans Müller</td>
							<td>Nutzer</td>
							<td>2021-05-01 12:00:00</td>
							<td>100</td>
						</tr>
					</tbody>
				</Table>
			</div>
			<div className="mt-4 d-block d-inline-flex  flex-column">
				<h5>Nutzer erfassen</h5>
				<form>
					<div className="form-group">
						<label htmlFor="username">Benutzername</label>
						<input type="text" className="my-2 form-control" id="username" placeholder="Benutzername"/>
					</div>
					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input type="text" className="my-2 form-control" id="name" placeholder="Name"/>
					</div>
					<div className="form-group">
						<label htmlFor="role">Rolle</label>
						<select className="my-2 form-control" id="role">
							<option>Admin</option>
							<option>Nutzer</option>
						</select>
					</div>
					<div className="form-group">
						<label htmlFor="password">Passwort</label>
						<input type="password" className="my-2 form-control" id="password" placeholder="Passwort"/>
						<small id="passwordHelp" className="form-text text-muted">Das Passwort muss mindestens 8 Zeichen lang sein.</small>
					</div>
					<div className="mt-2 form-group">
						<label htmlFor="password2">Passwort wiederholen</label>
						<input type="password" className="my-2 form-control" id="password2" placeholder="Passwort wiederholen"/>
					</div>
					<button type="submit" className="my-2 btn btn-primary">Speichern</button>
				</form>
			</div>
		</div>
	);
}



export default function ProfileSettings() {
	return (
		<div className="light-jq p-3">
			<h3 className="mb-4"> Profileeinstellungen</h3>
			<div>
				<h5>Passwort ändern</h5>
				<div className="my-3">
					<input placeholder="Aktuelles Passwort"/>
				</div>
				<div className="my-3">
					<input placeholder="Neues Passwort"/>
				</div>
				<div className="my-3">
					<input placeholder="Neues Passwort wiederholen"/>
				</div>
				<div className="my-3 d-flex flex-row">
					<div role={"button"} className={"p-2 dark-jq"} onClick={()=>alert("Neues Passwort erstellt!")}>Update</div>
					<div className="m-auto"/>
				</div>
				<h5 className="mt-5">Email ändern</h5>
				<div className="my-3">
					<input placeholder="Neue Email"/>
				</div>
				<div className="my-3 d-flex flex-row">
					<div role={"button"} className={"p-2 dark-jq"} onClick={()=>alert("Neues Passwort erstellt!")}>Update</div>
					<div className="m-auto"/>
				</div>
			</div>
		</div>
	);
}

import { useState,useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import _config from "./config.json";
const config = _config[process.env.NODE_ENV || "development"];


function Trunc({text, length=100}){
	const [open, setOpen] = useState(false);

	let truncd=text.length>length

	return (
		<span>
			{truncd&&open?text:text.substring(0,length)}
			{truncd&&<span className="text-primary" role="button" onClick={()=>setOpen(!open)}>{open?"<":"..."}</span>}
		</span>
	)
}

export default function TableView({selectedTab, setSelectedTab}) {
	const [data, setData] = useState(null);
	const [page, setPage] = useState(0);
	const [queryKey, setQueryKey] = useState('title');
	const [sortOrder, setSortOrder] = useState('asc');
	const [aiProps, setAiProps] = useState([]);

	const headers={
		title:"Titel",
		company:"Firma",
		type:"Typ",
		crawledAt:"Gecrawlt am",
		crawlId:"Crawl ID",
		placename:"Ort",
	}	


	useEffect(() => {

		setData(null);

		(async()=>{

			try{
				const result = await axios.get(config.apiUrl+"/jobsTable",
					{
						params: {
							order: sortOrder,
							queryKey: queryKey,
							lastPage: page
						}
					}
				)
				const aiPropsResult = await axios.get(config.apiUrl+"/aiprops")

				setData(result.data);
				setAiProps(aiPropsResult.data);

			}catch(error){
				console.log(error);
				setData("error "+error.message);
			}
		})()

	}, [page, queryKey, sortOrder]);





	return (
		<div>

			<div className="d-flex flex-row gap-2 m-1 align-items-center">
				<div role={"button"} className={"p-2 dark-jq "} onClick={()=>setPage(p=>Math.max(p-1,0))}>Zurück</div>
				{page}

				<div role={"button"} className={"p-2 dark-jq "} onClick={()=>setPage(p=>p+1)}>Weiter</div>
			</div>
			<Table striped bordered hover  variant="dark">
				<thead>
					<tr>
						<th>id</th>
						{
							Object.keys(headers).map((key)=>{
								return <th
									key={key}
									role="button"
									onClick={()=>{
										if(queryKey===key){
											setSortOrder(sortOrder==="asc"?"desc":"asc")
										}else{
											setQueryKey(key)
											setSortOrder("asc")
										}
										setPage(0)

									}}

								>{
									headers[key]
									} {queryKey===key?<i className={"bi "+(sortOrder==="asc"?"bi-caret-up-fill":"bi-caret-down-fill")}></i>:null}
								</th>
							})
						}
						{
							aiProps&&aiProps.map((aiProp)=>{
								return <th
									key={aiProp.title}
									role="button"
									onClick={()=>{
										if(queryKey==="aiProps."+aiProp.title){
											setSortOrder(sortOrder==="asc"?"desc":"asc")
										}else{
											setQueryKey("aiProps."+aiProp.title)
											setSortOrder("asc")
										}
										setPage(0)
									}}

								>{
									aiProp.title
									} {queryKey==="aiProps."+aiProp.title?<i className={"bi "+(sortOrder==="asc"?"bi-caret-up-fill":"bi-caret-down-fill")}></i>:null}
								</th>
							})
						}
					</tr>
				</thead>
				<tbody>
					{data===null?<tr><td colSpan={Object.keys(headers).length}>Loading...</td></tr>:
							typeof data === 'string'?<tr><td colSpan={Object.keys(headers).length}>{data}</td></tr>:
							data.map((row)=>{
								return <tr key={row._additional.id}>
									<td>{row._additional.id.substring(0,5)}</td>
									{
										Object.keys(headers).map((key)=>{
											return <td key={key}>
												<Trunc text={
													key==="crawledAt"?new Date(row[key]).toLocaleString("de"):row[key]
													} /></td>
										})
									}
									{
										aiProps.map((aiProp)=>{
											return <td key={aiProp.title}>
												<Trunc text={
													row.aiProps[aiProp.title]
													} />
											</td>
										})
									}
								</tr>
							}
							)

					}


				</tbody>
			</Table>
		</div>
	);


}

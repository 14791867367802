import {useState} from "react";
import SearchBar from "./SearchBar";
import axios from "axios";
import _config from "./config.json";
const config = _config[process.env.NODE_ENV || "development"];

export default function NewJob({selectedTab,setSelectedTab}){
	const [selectedLocation,setSelectedLocation]=useState(null);
	// states for each: jobTitle,jobDescription,company,location,type
	const [jobTitle,setJobTitle]=useState(null);
	const [jobDescription,setJobDescription]=useState(null);
	const [company,setCompany]=useState(null);
	const [type,setType]=useState(null);



	const postJob=async()=>{
		console.log(selectedLocation)
		try {
			const response=await axios.post(config.apiUrl+"/jobs",{jobTitle,jobDescription,company,location:selectedLocation.placename,type})
			alert("successfully added: "+JSON.stringify(response.data))
		} catch (error) {
			console.error(error);
			alert("error")
		}
	}


	return (
		<div className="light-jq p-3 d-flex flex-column">
			{/* Tabs */}
			<div className="d-flex flex-row gap-3 p-2">
				{["Jobs","Bilder"].map((n)=>
				<div key={n} role="button" className={"h5 "+(n===selectedTab?"":"text-muted")} onClick={()=>setSelectedTab(n)}>
					{n}
				</div>
				)
				}
			</div>
			<h3> Datenerfassung </h3>
			<div className="mt-4 d-block d-inline-flex  flex-column">

				<div>Jobtitel</div>
				<input className="form-control" type="text" placeholder="Jobtitel" onChange={(e)=>setJobTitle(e.target.value)}/>
				<div>Jobbeschreibung</div>
				<textarea className="form-control" type="text" placeholder="Jobbeschreibung" onChange={(e)=>setJobDescription(e.target.value)}/>
				<div>Firma</div>
				<input className="form-control" type="text" placeholder="Firma" onChange={(e)=>setCompany(e.target.value)}/>
				<div>Pensum</div>
				<input className="form-control" type="text" placeholder="Pensum" onChange={(e)=>setType(e.target.value)}/>
				<div>Location</div>
				<SearchBar {...{setSelectedLocation,selectedLocation,selectedDistance:null,setSelectedDistance:null,hideDistance:true}}/>
				<div className="d-flex flex-row mt-1">
					<div className="me-auto"/>
					<div role="button" className="p-2 dark-jq" onClick={()=>postJob()}>Post</div> 
				</div>
			</div>
		</div>
	);
}


import React, {useState} from 'react';
import * as Bootstrap from 'react-bootstrap';
import NavbarLinkTree from './NavbarLinkTree.js';
import Dropdown from 'react-bootstrap/Dropdown';
import {Link} from 'react-router-dom';

export default function Navbar(){
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showUser, setShowUser] = useState(false);
	const handleShowUser = () => setShowUser(true);
	const handleCloseUser = () => setShowUser(false);

	return(

		<div className="light-jq d-flex">
			<div className="ms-2 me-auto d-flex flex-column justify-content-center">
				<i style={{fontSize:"1.5em"}} className="bi bi-list d-xl-none" role="button" onClick={handleShow}></i>
			</div>
			<div className="me-auto d-flex flex-column justify-content-center" style={{minHeight:"30pt"}}>
				<span className="nb-title">Job Query</span>
			</div>
			<div className="me-2 d-flex flex-column justify-content-center" style={{minHeight:"30pt"}}>
				<Dropdown>
					<Dropdown.Toggle id="dropdown-basic">
						<span className="fw-bold" role="button"><i className="me-1 bi bi-person-circle"></i>Test User</span>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item><Link to="profile/settings">Einstellungen</Link></Dropdown.Item>
							<Dropdown.Item><Link to="profile/logout">Ausloggen</Link></Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<Bootstrap.Offcanvas show={show} onHide={handleClose} className="light-jq">
				<Bootstrap.Offcanvas.Header closeVariant="white" closeButton>
					<Bootstrap.Offcanvas.Title>Job Query</Bootstrap.Offcanvas.Title>
				</Bootstrap.Offcanvas.Header>
				<Bootstrap.Offcanvas.Body>
					<NavbarLinkTree closeNavbar={handleClose}/>
				</Bootstrap.Offcanvas.Body>
			</Bootstrap.Offcanvas>
		</div>
	)
}

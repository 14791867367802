import NavbarLinkTree from './NavbarLinkTree.js';


export default function SidePanel() {
	return (
		<div className="d-none p-3 d-xl-block light-jq text-left h-100">
			<NavbarLinkTree/>
		</div>
	)
}

import 'chart.js/auto';
import {Chart, Bubble} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js'
import {useState} from 'react'

import styles from './canvas.module.css'

ChartJS.defaults.color="white"

let switz=[[-1.089351,-0.893103],[-0.758116,-1.089795],[-0.388375,-1.143159],[-0.199808,-1.061173],[-0.003042,-1.188251],[0.230617,-1.114464],[0.361793,-1.208747],[0.328999,-1.253839],[0.226517,-1.208747],[0.144532,-1.274336],[0.349496,-1.454704],[0.501169,-1.294832],[0.648743,-1.290733],[0.632346,-1.237442],[0.857806,-1.270236],[1.144756,-1.110365],[1.308727,-0.975088],[1.456301,-0.917699],[1.267734,-0.56926],[1.255436,-0.306906],[1.673563,-0.257715],[1.661265,-0.163431],[1.886725,-0.024056],[2.157277,-0.245417],[2.259759,-0.159332],[2.169575,0.271092],[2.276156,0.320283],[2.247462,0.463758],[2.058895,0.422765],[2.0261,0.299787],[1.862129,0.353078],[1.829335,0.644126],[1.956413,0.62363],[1.894923,0.763005],[1.944115,0.873686],[1.853931,0.890083],[1.694059,0.676921],[1.329223,0.812197],[1.210344,0.500652],[1.025876,0.521148],[1.054571,0.758906],[0.77992,1.19343],[0.816813,1.365599],[0.743026,1.500876],[0.659487,1.473677],[0.661528,1.352351],[0.529591,1.231199],[0.581973,1.105014],[0.358397,0.996648],[0.18226,0.814453],[0.189954,0.52309],[0.033851,0.62363],[-0.150616,0.812197],[-0.056333,0.980267],[-0.228503,1.086849],[-0.298191,1.254919],[-0.437566,1.341004],[-0.704019,1.254919],[-1.09755,1.422989],[-1.216429,1.439387],[-1.359904,1.144239],[-1.470584,1.013062],[-1.421393,0.779403],[-1.515676,0.685119],[-1.445988,0.607233],[-1.802626,0.56624],[-2.073178,0.750708],[-1.970696,0.869587],[-2.151064,1.02536],[-2.34373,1.033558],[-2.323234,0.91058],[-2.151064,0.840892],[-2.097774,0.656424],[-2.220752,0.599034],[-2.163362,0.426865],[-2.192057,0.377673],[-1.786229,0.037433],[-1.831321,-0.167531],[-1.556669,-0.380693],[-1.204131,-0.778323],[-1.400896,-0.774224],[-1.294315,-0.917699],[-1.273819,-0.999684],[-1.081153,-1.007883],[-1.089351,-0.893103]]
let data=[
	{ x: 280, y: -79, r: 7028, label: 'Aargau' },
	{ x: 408, y: -90, r: 555, label: 'Appenzell Ausserrhoden' },
	{ x: 418, y: -98, r: 163, label: 'Appenzell Innerrhoden' },
	{ x: 235, y: -77, r: 2927, label: 'Basel Land' },
	{ x: 210, y: -56, r: 1959, label: 'Basel Stadt' },
	{ x: 202, y: -169, r: 10470, label: 'Bern' },
	{ x: 379, y: -151, r: 411, label: 'Glarus' },
	{ x: 165, y: -91, r: 737, label: 'Jura' },
	{ x: 264, y: -141, r: 4202, label: 'Luzern' },
	{ x: 303, y: -161, r: 438, label: 'Nidwalden' },
	{ x: 284, y: -182, r: 384, label: 'Obwalden' },
	{ x: 321, y: -28, r: 839, label: 'Schaffhausen' },
	{ x: 340, y: -146, r: 1636, label: 'Schwyz' },
	{ x: 217, y: -93, r: 2801, label: 'Solothurn' },
	{ x: 401, y: -119, r: 5191, label: 'St. Gallen' },
	{ x: 380, y: -56, r: 2859, label: 'Thurgau' },
	{ x: 330, y: -191, r: 370, label: 'Uri' },
	{ x: 319, y: -127, r: 1297, label: 'Zug' },
	{ x: 334, y: -76, r: 15642, label: 'Zürich' },
	{ x: 150, y: -207, r: 3297, label: 'Freiburg' },
	{ x: 35, y: -286, r: 5090, label: 'Genf' },
	{ x: 438, y: -209, r: 2013, label: 'Graubünden' },
	{ x: 346, y: -264, r: 3521, label: 'Tessin' },
	{ x: 88, y: -217, r: 8220, label: 'Waadt' },
	{ x: 209, y: -290, r: 3530, label: 'Wallis' },
	{x:116, y:-155, r:1761, label: 'Neuenburg'}
]

//568,365

// Note: changes to the plugin code is not reflected to the chart, because the plugin is loaded at chart construction time and editor changes only trigger an chart.update().
const image = new Image();
image.src = '/swiss.png';


let config={
	scales: {
		x: {
			min: 0,
			max: 568,
		},
		y: {
			min: -365,
			max: 0,
		}
	}
}


let options={
	scales: {
		x: {
			min: 0,
			max: 568,
		},
		y: {
			min: -365,
			max: 0,
		}
	},
	plugins: {
		tooltip: {
			callbacks: {
				label: (d)=>{
					if(d.dataset.data[d.dataIndex].jobs)
						return d.dataset.label+": "+Intl.NumberFormat('fr-FR').format(d.dataset.data[d.dataIndex].jobs)+" Jobs"
					else
						return "Schweiz"
				}
			}
		}
	}
}


export default function GeoStats() {

	//sort by name
	let _data=data.sort((a,b)=>{
		if(a.r<b.r) return 1
		if(a.r>b.r) return -1
		return 0
	})


	let chartData={
		datasets: _data.map((d)=>{
			return {
				label: d.label,
				data: [{x: d.x, y: d.y, r: Math.sqrt(d.r)/3,jobs: d.r}],
			}
		})
	}

	let lineDataset={
		data:switz.map((d)=>{return {x:(d[0]+2.55)*113,y:(d[1]+1.55)*-113}}),
		label:"Schweiz",
		type: 'line'
	}
	chartData.datasets=[lineDataset,...chartData.datasets]

	return(
		<div className={"p-3 light-jq d-flex flex-column "}>
			<h3>Geographie</h3>
					<Bubble data={chartData} options={options} config={config} />
		</div>
	)
}


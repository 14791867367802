import {DateTime} from "luxon";
import { useState,useEffect } from "react";
import _ from "lodash";
import SearchFilterModal from "./SearchFilterModal";
import axios from 'axios'
import _config from "./config.json";
import SearchBar from "./SearchBar";
import ReactMarkdown from 'react-markdown'
const config = _config[process.env.NODE_ENV || "development"];



function ImageListing({image,index,reload}){
	const [selected, setSelected]=useState(false)



	let prefix=config.apiUrl+"/imageFiles/"
	const cert=(image._additional.certainty*100).toFixed(2)
	return(
		<div className={
			selected?
				"col-12 p-0 m-0 flex-column d-flex mt-1":
				"col-lg-3 col-md-6 col-12 d-flex flex-column mt-1"
			}
		>
			<div className="h5"><span>{cert}%</span> - <span role="button" className="text-primary" onClick={()=>reload(image._additional.id)}>Ähnliche</span></div>
			<img src={prefix+image.filename} className="img-fluid" role="button" onClick={()=>setSelected(s=>!s)}/>
		</div>
	)
}

export default function AISearch({selectedTab,setSelectedTab}) {

	const [results, setResults] = useState(null);
	const [queryText, setQueryText] = useState("");


	const [hideSearchBox,setHideSearchBox]=useState(false)



	const reload=(queryImage)=>{

		setResults("loading")
		axios.get(config.apiUrl+"/images",{params:{
			...(queryImage?{queryImage}:{queryText}),
			numResults:100,
		}}).then((res)=>{


			setResults(res.data)
		}
		).catch((err)=>{
			console.log(err)
			setResults(null)
		}
		)
	}

	return (
		<>
			<div className={"light-jq d-flex flex-column "}>
				{/* Tabs */}
				<div className="d-flex flex-row gap-3 p-2">
					{["Jobs","Bilder"].map((n)=>
					<div key={n} role="button" className={"h5 "+(n===selectedTab?"":"text-muted")} onClick={()=>setSelectedTab(n)}>
						{n}
					</div>
					)
					}
					<div className="ms-auto"/>
					<i className={"bi me-2 "+(hideSearchBox?"bi-chevron-down":"bi-chevron-up")} role="button" onClick={()=>setHideSearchBox(h=>!h)}></i>
				</div>

				{/* Search params */}
				<div className={"p-3 pt-0 "+(hideSearchBox?"d-none":"")}>
					<textarea className="form-control" rows="10" placeholder="Suchbegriffe eingeben..." onChange={(e)=>setQueryText(e.target.value)}

						onKeyDown={(e)=>{
							if (e.key === 'Enter' && !e.shiftKey) {
								e.preventDefault();
								reload()
							}
						}}
					></textarea>
					<div className="d-flex gap-3 mt-2  align-items-center">

						<div className="flex-grow-1"></div>
						<div role={"button"} className={"p-2 dark-jq "} onClick={()=>reload()}>Suche</div>
					</div>
				</div>
			</div>
			{/* Search results*/}
			<div className="mt-2 light-jq p-2 flex-grow-1 d-flex flex-column">
				<div className="row p-3">
					{
						results===null?
							null
							:
							results==="loading"?

								<div className="d-flex flex-grow-1 justify-content-center align-items-center">
									<div className="spinner-border" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								</div>

								:
							results.map((result, index) => (
								<ImageListing key={index} image={result} index={index} reload={reload}/>
							)
							)
					}
				</div>
			</div>

		</>
	);

}

